import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{staticClass:"card",attrs:{"shaped":"","outlined":""}},[_c(VListItem,{staticClass:"seccion",attrs:{"dense":"","flat":""}},[_c(VListItemAction,{staticStyle:{"margin-right":"5px"}},[(_vm.permisoEscritura)?_c('menuSeccionComponent',{attrs:{"idSeccion":_vm.seccion._id},on:{"modificarSeccion":_vm.modificarSeccion,"eliminarSeccion":_vm.eliminarSeccion}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.seccion.nombre))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.ponderacion))])],1)],1),_c(VDivider),(_vm.seccion.indicadores.length != 0)?_c(VSimpleTable,{staticClass:"indicador"},[_c('thead',[_c('tr',[_c('th',{attrs:{"width":"2"}}),_c('th',{attrs:{"width":"2"}}),_c('th',{staticClass:"text-left"},[_vm._v("Indicador")]),_c('th',{staticClass:"text-center",attrs:{"width":"100"}},[_vm._v("Ponderación")]),_c('th',{staticClass:"text-center",attrs:{"width":"85"}},[_vm._v("PTS")])])])]):_vm._e(),_vm._l((_vm.seccion.indicadores),function(indicador){return _c('indicadorListaCotejoComponent',{key:indicador._id,attrs:{"indicador":indicador,"permisoEscritura":_vm.permisoEscritura},on:{"modificarIndicador":_vm.modificarIndicador,"eliminarIndicador":function($event){return _vm.eliminarIndicador(indicador)}}})}),(_vm.seccion.indicadores.length != 0)?_c(VDivider):_vm._e(),_c(VToolbar,{attrs:{"dense":"","flat":""}},[(_vm.seccion.indicadores.length == 0)?_c('span',{staticClass:"text-caption"},[_vm._v("No se han capturado indicadores")]):_vm._e(),_c(VSpacer),_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.permisoEscritura)?_c(VBtn,_vm._g({attrs:{"color":"primary","icon":"","disabled":_vm.loading || !_vm.agregarIndicadorDisponible},on:{"click":function($event){return _vm.agregarIndicador()}}},on),[_c(VIcon,[_vm._v("mdi-table-row-plus-after")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Agregar indicador")])])],1)],2),(_vm.abrirEditorIndicador)?_c('editarIndicadorComponent',{attrs:{"mostrar":_vm.abrirEditorIndicador,"idListaCotejo":_vm.idListaCotejo,"ponderacionMax":_vm.ponderacionMax,"editarIndicador":_vm.editarIndicador,"indicadorModificar":_vm.indicadorModificar,"idSeccion":_vm.seccion._id},on:{"cancelar":function($event){return _vm.cancelarIndicador()},"indicadorGuardado":_vm.indicadorGuardado,"indicadorActualizado":_vm.indicadorActualizado,"actualizarPuntosIndicadores":_vm.actualizarPuntosIndicadores,"statusInstrumento":_vm.statusInstrumento,"nuevoLog":_vm.nuevoLog}}):_vm._e(),(_vm.eliminarInd.mostrar)?_c('eliminarIndicadorComponent',{attrs:{"mostrar":_vm.eliminarInd.mostrar,"idListaCotejo":_vm.eliminarInd.idListaCotejo,"idSeccion":_vm.eliminarInd.idSeccion,"idIndicador":_vm.eliminarInd.idIndicador,"indicador":_vm.eliminarInd.indicador},on:{"cancelar":_vm.cancelarEliminarIndicador,"indicadorEliminado":_vm.indicadorEliminado,"actualizarPuntosIndicadores":_vm.actualizarPuntosIndicadores,"statusInstrumento":_vm.statusInstrumento,"nuevoLog":_vm.nuevoLog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }