<template>
  <v-container>
    <v-card shaped outlined>
      <v-list-item>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="permisoEscritura"
              v-on="on"
              class="title"
              small
              icon
              color="primary"
              :disabled="loading || !agregarSeccionDisponible"
              @click="mostrarAgregarSeccion()"
            >
              <v-icon>mdi-text-box-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Agregar sección</span>
        </v-tooltip>
      </v-list-item>

      <v-skeleton-loader
        v-if="loading"
        type="list-item-two-line@3"
        style="margin: 10px"
      ></v-skeleton-loader>

      <template v-else>
        <v-card
          shaped
          outlined
          style="margin: 10px"
          v-if="secciones.length === 0"
        >
          <v-toolbar dense flat class="text-bold">
            <span class="text--secondary"
              >No se han agregado secciones a esta lista cotejo.</span
            >
          </v-toolbar>
        </v-card>

        <seccionListaCotejoComponent
          v-for="seccion in secciones"
          :key="seccion._id"
          :idListaCotejo="idListaCotejo"
          :seccionA="seccion"
          :permisoEscritura="permisoEscritura"
          @modificarSeccion="modificarSeccion"
          @eliminarSeccion="quitarSeccion"
          @statusInstrumento="statusInstrumento"
          @nuevoLog="nuevoLog"
        />
      </template>
    </v-card>

    <!-- Componentes -->
    <editarSeccionComponent
      v-if="abrirEditorSeccion"
      :mostrar="abrirEditorSeccion"
      :idListaCotejo="idListaCotejo"
      :ponderacionMax="ponderacionMax"
      :editarSeccion="editarSeccion"
      @cancelar="(abrirEditorSeccion = false), (editarSeccion = null)"
      @seccionGuardada="seccionGuardada"
      @seccionActualizada="seccionActualizada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <eliminarSeccionComponent
      v-if="eliminarSeccion.mostrar"
      :mostrar="eliminarSeccion.mostrar"
      :idListaCotejo="idListaCotejo"
      :seccion="eliminarSeccion.seccion"
      @cancelar="cancelarEliminarSeccion"
      @seccionEliminada="seccionEliminada"
      @actualizarPuntosSecciones="actualizarPuntosSecciones"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import editarSeccionComponent from "./editarSeccionListaCotejo.vue";
import seccionListaCotejoComponent from "./seccionListaCotejo.vue";
import eliminarSeccionComponent from "./eliminarSeccionListaCotejo.vue";

import { getSeccionesListaCotejoService } from "../listaCotejo.service";

export default {
  name: "seccionListaCotejo",
  props: {
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    editarSeccionComponent,
    seccionListaCotejoComponent,
    eliminarSeccionComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
    agregarSeccionDisponible() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      return sumatoria < 100;
    },
  },
  watch: {
    sessionToken() {
      this.cargarSecciones();
    },
  },
  created() {
    this.idListaCotejo = this.$route.params.idInstrumento;
  },
  mounted() {
    if (this.sessionToken) this.cargarSecciones();
  },
  data() {
    return {
      loading: false,
      idListaCotejo: null,
      secciones: [],

      abrirEditorSeccion: false,
      editarSeccion: null,
      ponderacionMax: 100,

      eliminarSeccion: {
        mostrar: false,
        seccion: null,
      },

      abrirEditorIndicador: false,
      editarIndicador: null,
    };
  },
  methods: {
    statusInstrumento(data) {
      this.$emit("statusInstrumento", data);
    },
    nuevoLog(nuevoLog) {
      this.$emit("nuevoLog", nuevoLog);
    },
    async cargarSecciones() {
      try {
        this.loading = true;
        const serverResponse = await getSeccionesListaCotejoService(this.idListaCotejo);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.secciones = serverResponse.secciones;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    // ANALIZAR SI ESTA PARTE ES NECESARIA
    totalSeccion(id) {
      let index = this.secciones.findIndex((x) => x._id.toString() == id);
      let total = 0;
      if (index !== -1) {
        this.secciones[index].indicadores.forEach((indicador) => {
          total += indicador.ponderacion;
        });
      }

      return total;
    },
    mostrarAgregarSeccion() {
      let sumatoria = 0;
      if (this.secciones.length > 0) {
        this.secciones.forEach((seccion) => {
          if (seccion.ponderacion > 0) {
            sumatoria += seccion.ponderacion;
          }
        });
      }
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    seccionGuardada(nuevaSeccion) {
      this.secciones.push(nuevaSeccion);
    },
    modificarSeccion(idSeccion) {
      this.editarSeccion = {};
      let sumatoria = 0;
      this.secciones.forEach((seccion) => {
        if (seccion._id != idSeccion) {
          if (seccion.ponderacion > 0) sumatoria += seccion.ponderacion;
        } else this.editarSeccion = seccion;
      });
      this.ponderacionMax = 100 - sumatoria;
      this.abrirEditorSeccion = true;
    },
    seccionActualizada(nuevaSeccion) {
      const index = this.secciones.findIndex((x) => x._id == nuevaSeccion._id);
      if (index >= 0) {
        this.secciones[index].nombre = nuevaSeccion.nombre;
        this.secciones[index].ponderacion = nuevaSeccion.ponderacion;
      }
    },
    quitarSeccion(idSeccion) {
      this.eliminarSeccion.seccion = this.secciones.find(
        (x) => x._id == idSeccion
      );
      this.eliminarSeccion.mostrar = true;
    },
    seccionEliminada(idSeccion) {
      const index = this.secciones.findIndex((x) => x._id == idSeccion);
      if (index >= 0) this.secciones.splice(index, 1);
    },
    cancelarEliminarSeccion() {
      this.eliminarSeccion.mostrar = false;
      this.eliminarSeccion.seccion = null;
    },
    actualizarPuntosSecciones(data) {
      for (const seccion of data.seccionesActualizadas) {
        const index = this.secciones.findIndex((x) => x._id == seccion._id);
        if (index >= 0) this.secciones[index].puntos = seccion.puntos;
      }

      for (const indicador of data.indicadoresActualizados) {
        for (const i of indicador) {
          const index = this.secciones.findIndex((x) => x._id == i.idSeccion);

          const indexIndicador = this.secciones[index].indicadores.findIndex(
            (x) => x._id == i._id
          );

          if (indexIndicador >= 0)
            this.secciones[index].indicadores[indexIndicador].puntos = i.puntos;
        }
      }
    },
  },
};
</script>


