<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon small v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <!-- Modificar nombre lista cotejo -->
      <v-list-item @click="$emit('modificarNombre')">
        <v-list-item-title>Modificar nombre</v-list-item-title>
        <v-list-item-icon><v-icon small>mdi-pencil</v-icon></v-list-item-icon>
      </v-list-item>
      <v-list-item v-if="permisoEliminar" @click="$emit('eliminarLista')">
        <v-list-item-title>
          <font color="#D32F2F"> Eliminar lista de cotejo </font>
        </v-list-item-title>
        <v-list-item-icon
          ><v-icon color="red darken-2" small
            >mdi-trash-can-outline</v-icon
          ></v-list-item-icon
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "menuListaCotejo",
  props: ["permisoEliminar"],
  data() {
    return {
      loading: true,
    };
  },
};
</script>
