<template>
  <v-container>
    <v-card shaped outlined class="card">
      <v-list-item dense flat class="seccion">
        <v-list-item-action style="margin-right: 5px">
          <menuSeccionComponent
            v-if="permisoEscritura"
            :idSeccion="seccion._id"
            @modificarSeccion="modificarSeccion"
            @eliminarSeccion="eliminarSeccion"
          />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ seccion.nombre }}</v-list-item-title>
          <v-list-item-subtitle>{{ ponderacion }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-simple-table class="indicador" v-if="seccion.indicadores.length != 0">
        <thead>
          <tr>
            <th width="2"></th>
            <th width="2"></th>
            <th class="text-left">Indicador</th>
            <th width="100" class="text-center">Ponderación</th>
            <th width="85" class="text-center">PTS</th>
          </tr>
        </thead>
      </v-simple-table>

      <indicadorListaCotejoComponent
        v-for="indicador in seccion.indicadores"
        :key="indicador._id"
        :indicador="indicador"
        :permisoEscritura="permisoEscritura"
        @modificarIndicador="modificarIndicador"
        @eliminarIndicador="eliminarIndicador(indicador)"
      />
      <v-divider v-if="seccion.indicadores.length != 0"></v-divider>
      <v-toolbar dense flat>
        <span v-if="seccion.indicadores.length == 0" class="text-caption"
          >No se han capturado indicadores</span
        >
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="permisoEscritura"
              color="primary"
              icon
              v-on="on"
              :disabled="loading || !agregarIndicadorDisponible"
              @click="agregarIndicador()"
            >
              <v-icon>mdi-table-row-plus-after</v-icon>
            </v-btn>
          </template>
          <span>Agregar indicador</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <!-- Indicadores -->
    <editarIndicadorComponent
      v-if="abrirEditorIndicador"
      :mostrar="abrirEditorIndicador"
      :idListaCotejo="idListaCotejo"
      :ponderacionMax="ponderacionMax"
      :editarIndicador="editarIndicador"
      :indicadorModificar="indicadorModificar"
      :idSeccion="seccion._id"
      @cancelar="cancelarIndicador()"
      @indicadorGuardado="indicadorGuardado"
      @indicadorActualizado="indicadorActualizado"
      @actualizarPuntosIndicadores="actualizarPuntosIndicadores"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />

    <eliminarIndicadorComponent
      v-if="eliminarInd.mostrar"
      :mostrar="eliminarInd.mostrar"
      :idListaCotejo="eliminarInd.idListaCotejo"
      :idSeccion="eliminarInd.idSeccion"
      :idIndicador="eliminarInd.idIndicador"
      :indicador="eliminarInd.indicador"
      @cancelar="cancelarEliminarIndicador"
      @indicadorEliminado="indicadorEliminado"
      @actualizarPuntosIndicadores="actualizarPuntosIndicadores"
      @statusInstrumento="statusInstrumento"
      @nuevoLog="nuevoLog"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import menuSeccionComponent from "./menuSeccion.vue";

import indicadorListaCotejoComponent from "./indicadores/indicadorListaCotejo.vue";
import editarIndicadorComponent from "./indicadores/editarIndicadorListaCotejo.vue";
import eliminarIndicadorComponent from "./indicadores/eliminarIndicadorListaCotejo.vue";

export default {
  name: "seccionListaCotejo",
  props: {
    idListaCotejo: { type: String, default: "" },
    seccionA: { type: Object, default: () => null },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: {
    menuSeccionComponent,
    indicadorListaCotejoComponent,
    editarIndicadorComponent,
    eliminarIndicadorComponent,
  },
  computed: {
    ...mapGetters(["sessionToken"]),
    // Imprimir la ponderacion de las secciones
    ponderacion() {
      let ponderacion = "";
      if (this.seccion.ponderacion >= 0)
        ponderacion = `${this.seccion.ponderacion}%  -  ${this.seccion.puntos} pts`;
      else ponderacion = `Promedio  -  ${this.seccion.puntos} pts`;
      return ponderacion;
    },
    // Validar si se pueden agregar mas indicadores
    agregarIndicadorDisponible() {
      let sumatoria = 0;
      if (this.seccion.indicadores.length > 0) {
        this.seccion.indicadores.forEach((indicador) => {
          if (indicador.ponderacion > 0) {
            sumatoria += indicador.ponderacion;
          }
        });
      }
      return sumatoria < 100;
    },
    // Mandar la ponderacion maxima en indicadores
    ponderacionMax() {
      let sumatoria = 0;
      this.seccion.indicadores.forEach((indicador) => {
        sumatoria += indicador.ponderacion;
      });
      return 100 - sumatoria;
    },
  },
  watch: {},
  created() {
    this.seccion = this.seccionA;
  },
  data() {
    return {
      loading: false,
      seccion: null,
      abrirEditorIndicador: false,
      editarIndicador: false,
      indicadorModificar: null,
      //Se puede mejorar solo mandando el indicador
      eliminarInd: {
        mostrar: false,
        indicador: null,
        idIndicador: null,
        idSeccion: null,
        idListaCotejo: null,
      },
    };
  },
  methods: {
    nuevoLog(nuevoLog) {
      this.$emit("nuevoLog", nuevoLog);
    },
    statusInstrumento(data) {
      this.$emit("statusInstrumento", data);
    },
    modificarSeccion() {
      this.$emit("modificarSeccion", this.seccion._id);
    },
    eliminarSeccion() {
      this.$emit("eliminarSeccion", this.seccion._id);
    },
    agregarIndicador() {
      this.editarIndicador = false;
      this.abrirEditorIndicador = true;
    },
    cancelarIndicador() {
      this.editarIndicador = false;
      this.abrirEditorIndicador = false;
      this.indicadorModificar = null;
    },
    modificarIndicador(indicador) {
      this.indicadorModificar = indicador;
      this.editarIndicador = true;
      this.abrirEditorIndicador = true;
    },
    indicadorGuardado(nuevoIndicador) {
      this.seccion.indicadores.push(nuevoIndicador);
    },
    indicadorActualizado(nuevoIndicador) {
      const indicador = nuevoIndicador;

      const indexIndicador = this.seccion.indicadores.findIndex(
        (x) => x._id == indicador._id
      );

      this.seccion.indicadores[indexIndicador].descripcion =
        indicador.descripcion;
      this.seccion.indicadores[indexIndicador].ponderacion =
        indicador.ponderacion;
    },
    eliminarIndicador(indicador) {
      this.eliminarInd.indicador = indicador;
      this.eliminarInd.idIndicador = indicador._id;
      this.eliminarInd.idSeccion = indicador.seccion;
      this.eliminarInd.idListaCotejo = indicador.listaCotejo;
      this.eliminarInd.mostrar = true;
    },
    cancelarEliminarIndicador() {
      this.eliminarInd.indicador = null;
      this.eliminarInd.idIndicador = null;
      this.eliminarInd.idSeccion = null;
      this.eliminarInd.idListaCotejo = null;
      this.eliminarInd.mostrar = false;
    },
    indicadorEliminado(data) {
      const indexIndicador = this.seccion.indicadores.findIndex(
        (x) => x._id == data.idIndicador
      );
      this.seccion.indicadores.splice(indexIndicador, 1);
    },
    actualizarPuntosIndicadores(indicadoresActualizados) {
      for (const indicador of indicadoresActualizados) {
        const index = this.seccion.indicadores.findIndex(
          (x) => x._id == indicador._id
        );

        if (index >= 0)
          this.seccion.indicadores[index].puntos = indicador.puntos;
      }
    },
  },
};
</script>

<style scoped>
.card {
  transition: all 0.1s ease;
}

.card:hover {
  box-shadow: rgba(14, 84, 196, 0.2) 0px 2px 7px 0px;
}

.seccion {
  font-weight: bold;
  background-color: #f0f8ff;
}

.indicador {
  background-color: #effbff;
}
</style>

/<!--

#f0f8ff - azul bajito
#e7eef4 - azul fuerte

-->
