<template>
  <v-container>
    <v-skeleton-loader
      v-if="!escuelaTienePermiso"
      type="list-item-two-line@5"
    ></v-skeleton-loader>
    <v-card v-else rounded="lg">
      <v-toolbar dense color="secondary" dark flat class="text-h6">
        <v-btn icon @click="regresar"><v-icon>mdi-arrow-left</v-icon></v-btn>
        <v-spacer></v-spacer> {{ listaCotejo ? listaCotejo.nombre : "" }}
        <v-spacer></v-spacer>
        <menuListaCotejoComponent
          v-if="permisoEscritura"
          :permisoEliminar="listaCotejo.esAdmin"
          @modificarNombre="modificarNombre = true"
          @eliminarLista="eliminarLista"
        />
      </v-toolbar>

      <v-card-text v-if="loading">
        <loadingListaCotejoComponent />
      </v-card-text>

      <v-card-text v-else>
        <v-list-item>
          <v-card-text v-if="!modificarDescripcion">
            <div 
              v-if="listaCotejo.descripcion"
              v-html="listaCotejo.descripcion"
            >
            </div>

            <span v-else> <i>No se ha capturado descripción.</i> </span>
          </v-card-text>
          <modificarDescripcionComponent
            v-else
            :mostrar="modificarDescripcion"
            :descripcionActual="listaCotejo.descripcion"
            @cancelar="modificarDescripcion = false"
            @descripcionModificada="descripcionModificada"
            @nuevoLog="nuevoLog"
          />
          <v-tooltip top v-if="modificarDescripcion != true">
            <template v-slot:activator="{ on }">
              <button
                v-if="permisoEscritura"
                v-on="on"
                class="title"
                @click="modificarDescripcion = true"
              >
                <v-icon medium>mdi-square-edit-outline</v-icon>
              </button>
            </template>
            <span>Modificar descripción</span>
          </v-tooltip>
        </v-list-item>

        <v-divider class="mx-3"></v-divider>
        <p class="text-center">
          <instrumentoStatusComponent
            :status="listaCotejo.status"
            style="margin: 10px"
          />
        </p>

        <seccionesListaCotejoComponent
          :permisoEscritura="permisoEscritura"
          @statusInstrumento="statusInstrumento"
          @nuevoLog="nuevoLog"
        />
        <usuariosListaCotejoComponent
          :permisoEscritura="permisoEscritura"
          @nuevoLog="nuevoLog"
        ></usuariosListaCotejoComponent>
        <logsListaCotejoComponent
          v-if="listaCotejo && permisoEscritura"
          :logs="listaCotejo.logs"
        ></logsListaCotejoComponent>
      </v-card-text>
    </v-card>

    <modificarNombreComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombre="listaCotejo.nombre"
      @cancelar="modificarNombre = false"
      @nombreModificado="nombreModificado"
      @nuevoLog="nuevoLog"
    />

    <eliminarListaCotejoComponent
      v-if="eliminar.mostrar"
      :mostrar="eliminar.mostrar"
      :listaCotejo="eliminar.listaCotejo"
      @cancelar="cancelarEliminarLista"
      @listaCotejoEliminada="listaCotejoEliminada"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import menuListaCotejoComponent from "./menuListaCotejo.vue";
import loadingListaCotejoComponent from "./loadingListaCotejo.vue";
import instrumentoStatusComponent from "../instrumentoStatus.vue";
import seccionesListaCotejoComponent from "./secciones/seccionesListaCotejo.view.vue";

import modificarNombreComponent from "./modificarNombreLista.vue";
import modificarDescripcionComponent from "./modificarDescripcionLista.vue";
import eliminarListaCotejoComponent from "./eliminarListaCotejo.vue";
import usuariosListaCotejoComponent from "./usuariosListaCotejo.vue";
import logsListaCotejoComponent from "../../widgets/logs.vue";

import { getListaCotejoService } from "./listaCotejo.service";
import { permisoMenuEscuela } from "../../../helpers/utilerias";

export default {
  name: "listaCotejoView",
  props: {},
  components: {
    menuListaCotejoComponent,
    loadingListaCotejoComponent,
    instrumentoStatusComponent,
    seccionesListaCotejoComponent,
    modificarNombreComponent,
    modificarDescripcionComponent,
    eliminarListaCotejoComponent,
    usuariosListaCotejoComponent,
    logsListaCotejoComponent,
  },
  computed: {
    ...mapGetters(["colors", "sessionToken", "userId", "menu"]),
  },
  watch: {
    sessionToken(value) {
      if (value) {
        if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getInstrumento();
        }
      }
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Instrumentos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.getInstrumento();
      }
    }
  },
  data() {
    return {
      permisoEscritura: false,
      loading: true,
      listaCotejo: null,
      modificarNombre: false,
      modificarDescripcion: false,
      eliminar: {
        mostrar: false,
        listaCotejo: null,
      },
      escuelaTienePermiso: false,
    };
  },
  methods: {
    async getInstrumento() {
      try {
        this.loading = true;
        const { idInstrumento } = this.$route.params;

        const serverResponse = await getListaCotejoService(idInstrumento);
        this.loading = false;

        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.$router.push("/instrumentos#listas");
        } else {
          this.listaCotejo = serverResponse.listaCotejo;
          this.permisoEscritura = serverResponse.permisoEscritura;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    regresar() {
      if (!this.listaCotejo.esAdmin) {
        this.$router.push("/instrumentos#compartidos");
      }
      this.$router.push("/instrumentos#listas");
    },
    eliminarLista() {
      this.eliminar.mostrar = true;
      this.eliminar.listaCotejo = this.listaCotejo;
    },
    cancelarEliminarLista() {
      this.eliminar.mostrar = false;
      this.eliminar.listaCotejo = null;
    },
    listaCotejoEliminada() {
      this.$router.push("/instrumentos#listas");
    },
    nombreModificado(nombre) {
      this.modificarNombre = false;
      this.listaCotejo.nombre = nombre;
    },
    descripcionModificada(descripcion) {
      this.modificarDescripcion = false;
      this.listaCotejo.descripcion = descripcion;
    },
    statusInstrumento(data) {
      this.listaCotejo.status = data.status;
    },
    nuevoLog(nuevoLog) {
      this.listaCotejo.logs.unshift(nuevoLog);
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>
