<template>
    <v-dialog v-model="mostrar" persistent  :max-width="500">
        <v-card>
            <v-toolbar dense color="danger" dark flat class="text-h6">Eliminar indicador</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar el indicador?</b> <br>
                <ul><li>{{indicador.descripcion}}</li></ul>
                <i>Esta acción no podrá ser revertida.</i> </p>
            </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" 
                        small 
                        outlined 
                        :disabled="loading" 
                        @click="$emit('cancelar')">Cancelar</v-btn>
                        <v-btn color="danger" 
                        small
                        dark
                        :loading="loading" 
                        @click="eliminar()">Eliminar</v-btn>
                    </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarIndicadorListaCotejo } from "../../listaCotejo.service";
export default {
  name: "eliminarIndicador",
  props: {
    mostrar: Boolean,
    indicador: Object,
    idSeccion: String,
    idIndicador: String,
    idListaCotejo: String,
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {
    async eliminar() {
      try {
        this.loading = true;

        const serverResponse = await eliminarIndicadorListaCotejo(
          this.idListaCotejo,
          this.idSeccion,
          this.idIndicador
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const emitData = {
            idSeccion: this.idSeccion,
            idIndicador: this.idIndicador,
          };
          this.$emit("indicadorEliminado", emitData);
          this.$emit(
            "actualizarPuntosIndicadores",
            serverResponse.indicadoresActualizados
          );
          this.$emit("statusInstrumento", serverResponse.status);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>