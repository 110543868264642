<template>
  <v-container>
    <v-simple-table dense>
      <template v-slot:default>
        <tbody>
          <tr>
            <td width="2">
              <menuIndicadorComponent
                v-if="permisoEscritura"
                @modificarIndicador="modificarIndicador"
                @eliminarIndicador="eliminarIndicador"
              />
            </td>
            <td>{{ indicador.descripcion }}</td>
            <td
              v-if="indicador.ponderacion >= 0"
              width="125"
              class="text-center"
            >
              {{ indicador.ponderacion }}%
            </td>
            <td v-else width="125" class="text-center">Promedio</td>
            <td width="50" class="text-right">{{ indicador.puntos }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import menuIndicadorComponent from "./menuIndicador.vue";
export default {
  name: "indicadorListaCotejo",
  props: {
    indicador: { type: Object, default: () => {} },
    permisoEscritura: { type: Boolean, default: false },
  },
  components: { menuIndicadorComponent },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    modificarIndicador() {
      this.$emit("modificarIndicador", this.indicador);
    },
    eliminarIndicador() {
      this.$emit("eliminarIndicador", this.indicador);
    },
  },
};
</script>

<style scoped>
</style>
