<template>
  <v-dialog v-model="mostrar" width="500px" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark flat dense class="text-h6">
        <v-toolbar-title v-if="editarIndicador"
          >Editar Indicador</v-toolbar-title
        >
        <v-toolbar-title v-else>Agregar indicador</v-toolbar-title>
      </v-toolbar>
      <v-container style="margin-top: 15px">
        <v-textarea
          v-model="descripcion"
          label="Descripción"
          :disabled="loading"
          :error-messages="errors.descripcion"
          outlined
          dense
          rows="3"
          @input="validarDescripcion()"
          @blur="validarDescripcion()"
        ></v-textarea>
        <v-select
          v-model="ponderacion"
          :items="ponderaciones"
          label="Ponderación:"
          :disabled="loading"
          outlined
          dense
        ></v-select>
      </v-container>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{
            'disable-events':
              errors.descripcion.length > 0 || descripcion.length === 0,
          }"
          @click="guardarIndicador()"
          >Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import {
  crearIndicadorListaCotejoService,
  editarIndicadorListaCotejoService,
} from "../../listaCotejo.service";

export default {
  name: "editarIndicadorListaCotejo",
  props: {
    mostrar: { type: Boolean, default: false },
    idListaCotejo: { type: String, default: "" },
    ponderacionMax: { type: Number, default: null },
    editarIndicador: { type: Boolean, default: false },
    indicadorModificar: { type: Object, default: () => null },
    idSeccion: { type: String, default: "" },
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
  },
  validations: {
    descripcion: { required },
  },
  data() {
    return {
      loading: false,
      idIndicador: null,
      ponderaciones: [],
      descripcion: "",
      ponderacion: -1,
      errors: {
        descripcion: [],
      },
    };
  },
  created() {
    this.crearPonderaciones();
    if (this.editarIndicador) {
      this.idIndicador = this.indicadorModificar._id;
      this.descripcion = this.indicadorModificar.descripcion;
      this.ponderacion = this.indicadorModificar.ponderacion;
    }
  },
  methods: {
    validarDescripcion() {
      this.$v.descripcion.$touch();
      const errors = [];
      if (this.$v.descripcion.$dirty) {
        !this.$v.descripcion.required && errors.push("Campo requerido.");
      }
      this.errors.descripcion = errors;
    },
    async guardarIndicador() {
      try {
        let serverResponse = null;
        this.loading = true;

        const data = {
          descripcion: this.descripcion,
          ponderacion: this.ponderacion,
        };

        if (this.editarIndicador) {
          serverResponse = await editarIndicadorListaCotejoService(
            data,
            this.idListaCotejo,
            this.idSeccion,
            this.idIndicador
          );
        } else {
          serverResponse = await crearIndicadorListaCotejoService(
            data,
            this.idListaCotejo,
            this.idSeccion
          );
        }
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          if (!this.editarIndicador) {
            this.$emit("indicadorGuardado", serverResponse.nuevoIndicador);
          } else {
            this.$emit(
              "indicadorActualizado",
              serverResponse.indicadorModificado
            );
          }
          this.$emit(
            "actualizarPuntosIndicadores",
            serverResponse.indicadoresActualizados
          );
          this.$emit("statusInstrumento", serverResponse.status);
          this.$emit("nuevoLog", serverResponse.nuevoLog);
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async crearPonderaciones() {
      let ponderacionMax = this.ponderacionMax;
      if (this.editarIndicador)
        ponderacionMax += this.indicadorModificar.ponderacion;
      this.ponderaciones.push({ value: -1, text: "Promedio" });
      for (let i = 0; i <= ponderacionMax; i += 5)
        this.ponderaciones.push({ value: i, text: i + "%" });
    },
  },
};
</script>