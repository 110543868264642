import { patchServerRequest, getServerRequest, putServerRequest, deleteServerRequest } from "../../../services/serverRequest.service";
import { store } from '../../../../../store/store';

const httpURL = store.getters.httpURL;

export const getListaCotejoService = async (idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}`;
    return await getServerRequest(url, config);
};

export const actualizarNombreService = async (data, idListaCotejo) => {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/instrumento/listaCotejo/nombre/${idListaCotejo}`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
}

export const modificarDescripcionService = async (data, idListaCotejo) => {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/instrumento/listaCotejo/descripcion/${idListaCotejo}`;
    const config = { headers: { token: sessionToken } };
    return await patchServerRequest(url, data, config);
}

export const eliminarListaCotejo = async (idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}`;
    return await deleteServerRequest(url, config);
}

// Secciones
export const getSeccionesListaCotejoService = async (idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/secciones`;
    return await getServerRequest(url, config);
}

export const crearSeccionListaCotejoService = async (data, idListaCotejo) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/seccion`;
    return await putServerRequest(url, data, config);
}

export const editarSeccionListaCotejoService = async (data, idListaCotejo, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/seccion/${idSeccion}`;
    return await patchServerRequest(url, data, config);
}

export const eliminarSeccionListaCotejo = async (idListaCotejo, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/seccion/${idSeccion}`;
    return await deleteServerRequest(url, config);
}

// Indicadores
export const crearIndicadorListaCotejoService = async (data, idListaCotejo, idSeccion) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/indicador/${idSeccion}`;
    return await putServerRequest(url, data, config);
}

export const editarIndicadorListaCotejoService = async (data, idListaCotejo, idSeccion, idIndicador) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/indicador/${idSeccion}/${idIndicador}`;
    return await patchServerRequest(url, data, config);
}


export const eliminarIndicadorListaCotejo = async (idListaCotejo, idSeccion, idIndicador) => {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/${idListaCotejo}/indicador/${idSeccion}/${idIndicador}`;
    return await deleteServerRequest(url, config);
}

// colaboradores y compartido
export async function obtenerUsuariosListaCotejoService(tipo, idListaCotejo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/instrumento/listaCotejo/usuarios/${tipo}/${idListaCotejo}`;
    return await getServerRequest(url, config);
}
  
  export async function agregarUsuarioListaCotejoService(tipo, idListaCotejo, idUsuario) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const data = { idUsuario };
    const url = `${httpURL}/instrumento/listaCotejo/usuarios/${tipo}/${idListaCotejo}`;
    return await putServerRequest(url, data, config);
  }